.card-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-outside{
    width: 350px;
    aspect-ratio: 2/3;
    background-color: var(--red);
    background-color: var(--pink);
    border-radius: 0.5rem;
    box-shadow: var(--shadow-mauve);
    color: var(--red);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    z-index: 1;
}

.card-container.closed .card-outside.no-interact::after{
    position: absolute;
    content: "Click Me!";
    bottom: -2rem;
    right: -5rem;
    font-size: 1.25rem;
    animation: scale 2000ms ease-in-out infinite;
}

@keyframes scale {
    50%{
        transform: scale(0.8);
    }
}

.card-container.opened .card-outside{
    right: -5px;
}

.pic-container{
    display: inline-block;
    position: relative;
    width: 65%;
    top: -30px;
}

.person-container{
    aspect-ratio: 1 / 1.25;
    display: grid;
    align-items: end;
    clip-path: polygon(0 0, 100% 0, 100% 76%, 70% 100%, 30% 100%, 0 76%);
}

.hexagon{
    position: absolute;
    inset: auto 0 0;
    margin-inline: auto;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    transition: var(--transition);
    object-position: left;
}

.profile-pic{
    position: relative;
    width: 92.5%;
    margin-inline: auto;
    top: 1.75rem;
    object-fit: cover;
    transition: var(--transition);
}

.card-container.opened .profile-pic{
    transform: scale(1.05) translateY(-1rem);
}

.card-container.opened .hexagon{
    object-position: right;
}

.title-container{
    position: relative;
    z-index: 1;
}

.title-container::after{
    content: "";
    position: absolute;
    width: 50%;
    left: 25%;
    bottom: -1rem;
    margin: auto;
    border-bottom: 2.5px solid var(--mauve-trans);
}

.card-outside .about{
    z-index: 1;
    color: var(--red);
    display: inline-flex;
    gap: 1rem;
}

/* right part */
.card-inside{
    width: 450px;
    min-height: max-content;
    aspect-ratio: 3 / 3.25;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 1rem;
    border-radius: 0.5rem;
    background-color: var(--secondary);
    box-shadow: var(--shadow-mauve);
    color: var(--red);
    position: relative;
    left: -5px;
}

.inside-heading{
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
}

.toggle{
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 99;
}

.inside-heading .header{
    margin-left: 1rem; 
    font-size: 1.75rem;
    font-weight: 900;
    letter-spacing: 1px;
    position: relative;
}

.inside-heading .header::after{
    content: "";
    position: absolute;
    width: 50%;
    left: 0;
    bottom: 0;
    border-bottom: 2px solid var(--mauve-trans);
}

/* Content */
.inside-content{
    width: 100%;
    height: 100%;
    margin: 2rem 1rem 1rem;
    overflow-x: hidden;
    overflow-y: scroll;
}

.inside-content .block{
    height: 100%;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.inside-content .block ul{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

/* When screen size are smaller than card outside & inside(350px+450px) */
@media only screen and (max-width: 800px) {
    .card-outside{
        width:300px;
    }

    .card-inside{
        width: 350px;
        aspect-ratio: 6 / 7;
    }
}

@media only screen and (max-width: 650px) {
    .card-container{
        padding: 0 5px;
        margin: 1rem 0;
        width: 100%;
        position: relative;
    }

    .card-outside{
        width: clamp(250px, 95vw, 400px);
    }

    .card-container.closed .card-outside.no-interact::after{
        right: 0;
        font-size: 1.25rem;
    }

    .card-container.opened .card-outside{
        right: 0;
    }

    .card-inside{
        position: absolute;
        width: clamp(250px, 95vw, 400px);
        height: 100%;
        aspect-ratio: 2 / 3;
        left: auto ;
        z-index: 1;
    }
}