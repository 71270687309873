.scrollGallery{
    display: flex;
    width: 100%;
    height: 100%;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    color: var(--red);
}

/* Intro */
.scrollSwiper-intro {
    margin-left: 5vw;
}

.scrollSwiper-intro .section-heading {
    text-align: left;
}

.scrollSwiper-intro .section-heading::after {
    left: 0;
    transform: translateX(0);
}

/* Main */
.scrollSwiper-content{
    width: clamp(300px, 65%, 1200px);
    width: 65%;
    height: 100%;
    max-height: calc(100vh - var(--nav-height) * 2 - 100px);
    position: relative;
}

.gallery-slide img {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    object-position: 0 30%;
    cursor: pointer;
}

/* descriptions */
.description{
    width: 90%;
    margin: 1rem auto;
    text-align: left;
}


.description .heading{
    letter-spacing: 2px;
}

.description .heading .id{
    font-size: 2rem;
}

.description .intro{
    padding: 1rem 0;
}

.description .open{
    font-size: 1.25rem;
    text-align: end;
    border-bottom: 1px var(--red) solid;
    cursor: pointer;
}

/* Navigation */
.swiper-button{
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 1rem;
    z-index: 100;
}

.image-swiper-button-prev,
.image-swiper-button-next {
    color: var(--red);
    background-color: var(--pink);
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.swiper-button-disabled {
    opacity: 0.5;
    pointer-events: unset;
    cursor: unset;
}

@media only screen and (max-width: 850px) {
    .scrollGallery{
        flex-direction: column;
    }

    .scrollSwiper-intro {
        margin: 0;
    }

    .scrollSwiper-intro .section-heading::after{  
        left: 50%;
        transform: translateX(-50%);
    }

    .scrollSwiper-content{
        width: 100%;
    }
}

@media only screen and (max-width: 650px) {
    .scrollSwiper-content{
        padding: 0 1rem;
    }

    .swiper-button{
        right: calc(1rem + 10px);
    }
}