/* Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    overflow: auto;
    padding: 64px 0;
    z-index: 99999;
    transition: var(--transition);
}
  
.modal-content {
    position: relative;
    width: 80%;
    min-height: 100%;
    background: var(--secondary);
    margin: auto;
    padding: 5vh;
    border-radius: 5px;
    box-shadow: var(--shadow2);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.modal-close-button {
    position: absolute;
    width: 50px;
    aspect-ratio: 1/1;
    top: 50px;
    right: 25px;
    color: var(--secondary);
    background-color: var(--light-mauve);
    border: var(--secondary) 1px solid;
    border-radius: 50%;
    transition: var(--transition);
    z-index: 999999;
}

.modal-close-button:hover{
    background-color: var(--mauve);
    scale: 1.1;
}

@media only screen and (max-width: 650px) {
    .modal-content {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}