.thumbsGallery{
    max-width: 80vw;
    height: auto;
    padding: 1rem;
}

.thumbsSwiper-top {
    width: 100%;
    height: 75vh;
}

.thumbsSwiper-top .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbsSwiper-top .swiper-slide img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.thumbsSwiper-top .swiper-button-prev,
.thumbsSwiper-top .swiper-button-next {
    color: var(--red);
}

.thumbsSwiper-bot {
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    padding-top: 10px;
}

.thumbsSwiper-bot .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.thumbsSwiper-bot .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thumbsSwiper-bot .swiper-slide-thumb-active {
    opacity: 1;
}