.welcomeContainer{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.welcomePicContainer{
    height: clamp(200px, 45vh, 400px);
    aspect-ratio: 4 / 5 ;
    background-color: var(--light-mauve);
    box-shadow: var(--shadow-mauve);
}

.welcomePic{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.fancyBG{
    border-radius: 32% 68% 52% 48% / 45% 49% 51% 55%;
    animation: fancy infinite 10s linear;
}

@keyframes fancy {
    0%{
        border-radius: 32% 68% 52% 48% / 45% 49% 51% 55%;
    }   
    25%{
        border-radius: 57% 43% 40% 60% / 62% 59% 41% 38% ;
    } 
    75%{
        border-radius: 62% 38% 61% 39% / 44% 38% 62% 56% ;
    }
    100%{
        border-radius: 32% 68% 52% 48% / 45% 49% 51% 55%;
    }
}

/* right side */
.welcomeTitle{
    background: linear-gradient(0deg, var(--red) 0%, var(--pink) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 1.5px;
}

.welcomeTitle h1{
    font-size: 5rem;
}

@media only screen and (max-width: 650px) {
    .welcomeContainer{
        flex-direction: column;
    }
}