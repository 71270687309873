.contact-container{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 1rem;
}

.contact-card{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-card .contact-img {
    width: clamp(250px, 20vw, 500px);
    height: clamp(250px, 20vw, 500px);
    border-radius: 50%;
    object-fit: cover;
    object-position: 0 80%;
    box-shadow: var(--shadow);
    border: 10px var(--mauve) solid;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 650px) {
    .contact-container {
        flex-direction: column;
        gap: auto;
    }
}
