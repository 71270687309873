.section-heading {
    align-self: center;
    text-align: center;
    color: var(--mauve);
    position: relative;
}

#testimonials .section-heading{
    margin: 100px auto 0px;
} 

#about .section-heading,
#contact .section-heading{
    position: absolute;
    top: calc(var(--nav-height) + 20px);
    left: 50%;
    transform: translateX(-50%);
}

.section-heading .title {
    font-size: 2rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.section-heading .subtitle {
    margin-bottom: 0.5rem;
    color: var(--accent);
}

.section-heading::after {
    position: absolute;
    content: "";
    inset: auto 0 0 0;
    width: 25%;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    border-bottom: 2px solid var(--pink);
}

#testimonials .section-heading::after {
    border-bottom: 2px solid var(--background);
}

@media only screen and (max-width: 650px) {
    #about .section-heading,
    #contact .section-heading{
        position: relative;
        top: 0;
        left: 0;
        transform: translateX(0);
        margin: calc(var(--nav-height) + 30px) auto 30px;
    }
}

@media only screen and (max-height: 900px) {
    #about .section-heading,
    /* #gallery .section-heading, */
    #contact .section-heading,
    #testimonials .section-heading {
        position: relative;
        top: 0;
        left: 0;
        transform: translateX(0);
        margin: calc(var(--nav-height) + 30px) auto 30px;
    }
}