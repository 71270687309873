.review-card-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 100px;
}

.review-swiper {
    display: flex;
    width: clamp(300px, 75vw, 800px);
    transition: var(--transition);
    overflow: visible !important;
}

.review-card {
    min-width: 100%;
    height: auto !important;
    padding: 3rem 3rem;
    border: 5px solid var(--mauve-trans);
    color: var(--mauve);
    border-radius: 0.75rem;
    position: relative;
    display: flex !important;
    flex-direction: column;
    gap: 2.5rem;
    justify-content: center;
    align-items: center;
}

.review-card .quote {
    position: absolute !important;
    fill: var(--mauve);
    background-color: var(--pink);
    width: 75px;
    height: 75px;
}

.review-card .quote.normal {
    top: -2.6rem;
    left: -2.6rem;
}

.review-card .quote.upside-down {
    bottom: -2.6rem;
    right: -2.6rem;
}

.review-card .date {
    position: absolute;
    top: -1.3rem;
    right: 1rem;
    padding: 0 1rem;
    font-weight: bolder;
    font-size: 1.5rem;
    background-color: var(--pink);
}

.review-card .rating-list {
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    justify-content: space-evenly;
}

.review-card .stars-container {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.review-card .comment-container .comment-content {
    width: 80%;
    margin: 1rem auto;
}

.review-card-list .comment-title,
.review-card-list .rating-titles {
    letter-spacing: 2.5px;
    font-weight: 650;
    position: relative;
    width: fit-content;
    margin: auto;
}

.review-card-list .comment-title::after{
    content: "";
    position: absolute;
    left: calc((50% - 50% / 2));
    bottom: 0;
    width: 50%;
    border-bottom: 1.5px solid var(--mauve-trans);
}

.review-card-list .swiper-button-prev,
.review-card-list .swiper-button-next {
    color: var(--mauve);
}

.review-card-list .swiper-button-prev {
    left: -75px;
}

.review-card-list .swiper-button-next {
    right: -75px;
}

.review-card-list .swiper-pagination-bullet-active {
    background: var(--mauve);
}

.review-card-list .swiper-pagination {
    bottom: -50px !important;
}

.review-card-list .autoplay-progress {
    position: absolute;
    left: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--mauve);
}

.review-card-list .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: var(--mauve-trans);
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
}

@media only screen and (max-width: 650px) {
    .review-swiper {
        margin-bottom: 100px;
    }

    .review-card-list{
        margin-top: 50px;
    }

    .swiper-button-prev {
        left: 10px !important;
    }

    .swiper-button-next {
        right: 10px !important;
    }
}
