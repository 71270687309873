@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

:root {
  --background: rgb(236, 224, 223);
  --text: rgb(20, 12, 0);
  --primary: rgb(191, 183, 171);
  --secondary: rgb(253, 252, 251);
  --accent: rgb(113, 122, 136);
  --red: rgb(82, 6, 6);
  --mauve: rgb(134, 81, 81);
  --mauve-trans: rgba(134, 81, 81, 0.3);
  --light-mauve: #a98383;
  --pink: #cfb9b9;
  --font-family: 'Lato', sans-serif;
  --shadow: 0 2.5rem 2rem -2rem hsl(200 50% 20% / 40%);
  --shadow2: 0 0 0 1px #e6e8eb, 0 14px 45px 0 rgba(0,0,0,.12);
  --shadow-mauve: 0 0 0 2px var(--mauve), 0 14px 45px 0 rgba(0,0,0,.12);
  --transition: 350ms ease-out;
  --nav-height: 66px;
}

#root{
  width: 100vw;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  color: var(--text);
  background-color: var(--background);
  font-family: var(--font-family);
  line-height: 1.5;
}

a {
  text-decoration: none;
  width: fit-content;
  height: fit-content;
}

li {
  list-style: none;
}

button {
  cursor: pointer;
}

textarea {
  font-family: var(--font-family);
  resize: none;
}

section {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hide {
  display: none;
  opacity: 0;
}

.icon{
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  transition: var(--transition);
}

.modal-open{
  overflow: hidden;
}

@media only screen and (max-width: 650px) {
  #root{
    min-height: 100vh;
  }
}

/* Screen size for landscape mode */
@media only screen and (max-height: 750px) {
  .card-container{
    margin-top: 1rem;
  }
}

/* Screen size for Galaxy Fold */
@media only screen and (max-width: 280px) {
  .icon{
    width: 2rem;
    height: 2rem;
    margin: 1rem 0.5rem;
  }
}